import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './About';
import Contacts from './Contacts/Contacts';
import GiftCard from './GiftCard/GiftCard';
import Prices from './Prices/Prices';
import Services from './Services/Services';
import Admin from '../Admin/Admin';
import Home1 from './Home1';
import Gallery from './Gallery/Gallery';
import GalleryResults from './Gallery/GalleryResults';
import GalleryQualifications from './Gallery/GalleryQualifications';
import Registration from './Registration/Registration';

function Pages() {

  return (
            <Routes>
                <Route index element={<Home1 />} />
                <Route path='/' element={<Home1 />} />
                <Route path='/about' element={<About />} />
                <Route path='/registration' element={<Registration />} />
                <Route path='/services' element={<Services />} />
                <Route path='/giftcard' element={<GiftCard />} />
                {/* <Route path='/prices' element={<Prices />} /> */}
                <Route path='/contacts' element={<Contacts />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/gallery/galleryresults' element={<GalleryResults />} />
                <Route path='/gallery/galleryqualifications' element={<GalleryQualifications />} />
                <Route path='/admin' element={<Admin />} />
                <Route path='/*' element={<Home1 />} />
            </Routes>
  )
}

export default Pages