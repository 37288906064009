import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import React, { useState } from 'react';
import * as Gricons from "react-icons/gr";
import { db } from '../../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalVizitui from '../../Modal/ModalVizitui';

function Registration({closeVizita}) {

  const [openVizitui, setOpenVizitui] = useState(false);

  // style.backgroundColor = "green"
  
  function resetForm(e) {
    document.getElementById("form-reg-reset").reset();
     document.querySelectorAll('.modal-service').forEach( btn => 
     btn.classList.remove('active'))

  }

  const [status, setStatus] = useState(undefined);
  const [form, setForm] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  }
const te = document.getElementsByClassName("modal-close-vizitas")[0];
// te.style.backgroundColor = 'yellow';
console.log(te);
  async function handleClientReg(e) {
    e.preventDefault();
    const created_timestamp = serverTimestamp();

    const res = await addDoc(collection(db, "ClientRegistration"), {
      email: form.email,
      tel: form.tel,
      service: form.service,
      created_at: created_timestamp
    }).then((res) => {
      setStatus({ type: 'success' });
      resetForm();
    })
    .catch((error) => {
      setStatus({ type: 'error', error });
    });
  }


  
  const handleClick = (e) => {
    e.preventDefault();
    setField('service', e.target.textContent)

    const currTarget = e.currentTarget;   
     // disable all 
     document.querySelectorAll('.modal-service').forEach( btn => 
     btn.classList.remove('active'))
     currTarget.classList.add('active');  
  }



  return (
    // <div className='modal-background'>
    //   <form className="modal-form" onSubmit={handleClientReg} id='form-reg-reset'>
       
    //     <h1 className="modal-title">Registracija</h1>

    //     <p>Užsiregistruokite konsultacijai arba veido priežiūros procedūrai.</p>

    //     <div className="modal-item" onSubmit={handleClientReg} id='form-reg-reset'>
    //       <label htmlFor="" className="modal-label">El. paštas</label>
    //       <input type="email" className="modal-input" placeholder=" " onChange={(e) => setField('email', e.target.value)} />
    //     </div>
    //     <div className="modal-item">
    //       <label htmlFor="" className="modal-label">Tel. numeris</label>
    //       <input type="number" className="modal-input" placeholder=" " onChange={(e) => setField('tel', e.target.value)} />
    //     </div>
    //     <div className="modal-item">
    //       <label htmlFor="" className="modal-label">Paslauga:</label>
    //       <div className='modal-dropdown'>
    //         <span className='modal-service' onClick={handleClick}>Konsultacija (iki 30min.)</span>
    //         <span className='modal-service' onClick={handleClick} value='resetForm'>Veido procedūra</span>
    //       </div>

    //     </div>
    //     <input type="submit" className="btn-all" value="Siųsti" />
    //   </form>
    //  </div>
    <ModalVizitui rule={true} closeVizita={setOpenVizitui}/>
  )
}

export default Registration

