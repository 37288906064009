import React, { useEffect, useState } from 'react';
import ModalKonsultacija from '../Modal/ModalKonsultacijai';
import ModalVizitui from '../Modal/ModalVizitui';
import About from './About';
import Contacts from './Contacts/Contacts';
import Feedback from './Feedbacks/Feedback';
import './pagesStyle.css';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase';
import Brands from './Brands/Brands';


function Home1() {
  const [openVizitui, setOpenVizitui] = useState(false);
  const [openKonsultacijai, setOpenKonsultacijai] = useState(false);
  const [title, setTitle] = useState();
  const [desc, setDesc] = useState();
  const [signature, setSignature] = useState();

  async function fetchAboutMe() {
    const docRef = doc(db, "AllTexts", "aboutme");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setTitle(docSnap.data().title)
      setDesc(docSnap.data().desc)
      setSignature(docSnap.data().signature)
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  }



  useEffect(() => {
    fetchAboutMe();
  }, [])
  return (

    <div className="Home1">
      <div className='Home1-container'>
        <div className="item1">
          <img src="./images/about.jpg" alt="" className="Home1-image" />
          <div className="btn-reg-container">
            <div className="btn-all" onClick={() => { setOpenVizitui(true) }}>Registruotis vizitui</div>
          </div>
        </div>
        <div className="item2">
          <h3 className='Home1-title'>{title}</h3>
          <p className="Home1-desc">{desc}</p>
          <div className="signature-container">
            <p className="firstname-lastname">{signature}</p>
            <img src="./images/signature.png" alt="sign" className="signature" />
          </div>
        </div>


        {/* <div className="btn-all">Apie mane</div> */}
      </div>
      {openVizitui && <div className="modal"><ModalVizitui btn={true} rule={false} closeVizita={setOpenVizitui} /></div>}
      {/* {openKonsultacijai && <div className="modal"><ModalKonsultacija closeKonsultacija={setOpenKonsultacijai} /></div>} */}

      {/* <About /> */}
      <Feedback />
      <Brands />
      <Contacts />
    </div>
  )
}

export default Home1