import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { db } from "../../firebase";

const ServicesItem = ({
    itemID,
    itemTitle,
    itemPrice,
    itemDesc,
    itemDuration
}) => {
    const [isSelected, setIsSelected] = useState(false);
    const [title, setTitle] = useState(itemTitle);
    const [price, setPrice] = useState(itemPrice);
    const [desc, setDesc] = useState(itemDesc);
    const [duration, setDuration] = useState(itemDuration);
    const [docID, setDocID] = useState("");
    const [button, setButton] = useState("Redaguoti");
    const [cancel, setCancel] = useState(false);

    const durationTime = [
        "15 min",
        "30 min",
        "45 min",
        "1 val",
        "1 val 15 min",
        "1 val 30 min",
        "1 val 45 min",
        "2 val",
        "2 val 15 min",
        "2 val 30 min",
        "2 val 45 min",
        "3 val"
    ]
    const items = durationTime.map((item, index) => {
        return (
            <option key={index} value={item}>{item}</option>

        )
    })

    const editBtn = (e) => {
        e.preventDefault();
        setIsSelected(!isSelected); // ijungia redagavima jei true
        setDocID(itemID); // nustato esancio iraso ID kad tik ji atnaujinti
        setButton("Atnaujinti"); // nustato migtuko pavadinima
        console.log(e.target.textContent);
        const btnHide = e.target.textContent; // gauna paspausto migtuko pavadinima
        let allButtons = document.querySelectorAll(".service-updateBtn-container > button.btn-all");

        if (btnHide === "Redaguoti") {
            setCancel(true);
            allButtons.forEach(btn => {
                btn.style.display = "none";
                e.target.style.display = "inline-flex";
            });
        }
        else if (btnHide === "Atnaujinti") {
            updateBtn();
            setButton("Redaguoti");
            setCancel(false);
            allButtons.forEach(btn => {
                btn.style.display = "inline-flex";
            });
            // console.log("Atnaujinta");
        }
    }

    const updateBtn = async () => {
        const update = doc(db, "priceList", docID);
        await updateDoc(update, {
            "title": title,
            "price": price,
            "desc": desc,
            "duration": duration
        });
        // setButton("Redaguoti");
        // console.log("Atnaujinta");
    }

    const cancelBtn = (e) => {
        e.preventDefault();
        let allButtons = document.querySelectorAll(".service-updateBtn-container > button.btn-all");
        setIsSelected(!isSelected);
        setButton("Redaguoti");
            setCancel(false);
            allButtons.forEach(btn => {
                btn.style.display = "inline-flex";
            });
    }
    return (
        
        <div className="service-update-item">
            <div className="nav-menu-lines"></div>
            <div className="service-update-title-container">
                {!isSelected ? <span className="service-title">{itemTitle}</span> :
                    <input type="text" className="btn-all" value={title} onChange={(e) => setTitle(e.target.value)} />}
                {!isSelected ? <span className="service-price">nuo {itemPrice}€</span> :
                    <span className="service-price">nuo <input type="number" className="btn-all" value={price} onChange={(e) => setPrice(e.target.value)} />€</span>}
            </div>
            {!isSelected ? <span className="service-duration">iki {itemDuration}</span> :
                <select value={duration} onChange={(e) => setDuration(e.target.value)}>
                    {items}
                </select>
            }
            <div className="row">
                {!isSelected ? <span className="service-desc">{itemDesc}</span> :
                    <textarea className="textarea-update" value={desc} onChange={(e) => setDesc(e.target.value)}>{desc}</textarea>}
                    <div className="service-updateBtn-container">

                <button className={!isSelected ? "btn-all" : "btn-update"} onClick={editBtn}>{button}</button>
                {cancel ? <button className="btn-all cancel" onClick={cancelBtn}>Atšaukti</button> : null}
                    </div>

            </div>
        </div>
    );
};

export default ServicesItem;
