import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../../firebase';
import Masonry from 'react-responsive-masonry';

function GalleryQualifications() {
    const [photos, setPhotos] = useState([]);
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [column, setColumn] = useState();

    const fetchPost = async () => {
       
        await getDocs(query(collection(db, "galleryQualification"), orderBy('created_at', 'desc')))
            .then((querySnapshot)=>{               
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id }));
                setPhotos(newData);                
                // console.log(photos, newData);
            })
       
    }
    
    useEffect(()=>{
        fetchPost();
    }, [])

    function getCurrentDimension(){
    	return window.innerWidth;
  	}
  
  	useEffect(() => {
    		const updateDimension = () => {
      			setScreenSize(getCurrentDimension())
    		}
    		window.addEventListener('resize', updateDimension);
    
		
    		return(() => {
                if(screenSize > 800) {
                    setColumn(3);
                } else if(screenSize > 500 && screenSize < 800) {
                    setColumn(2);
                } else {
                    setColumn(1);
                }
        		window.removeEventListener('resize', updateDimension);
    		})
  	}, [screenSize])
  
  return (
    <div className='gallery-all'>
          <Masonry columnsCount={column} gutter="10px">
              {photos.map((image, i) => (
                  <img
                      key={i}
                      src={image.url}
                      style={{ width: "100%", display: "block" }}
                  />
              ))}
          </Masonry>
    </div>
  )
}

export default GalleryQualifications
