import React, { useState } from 'react';
import './modalStyle.css';
import * as Gricons from "react-icons/gr";
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../firebase';
import PopUp from './PopUp';

function ModalVizitui({ closeVizita, btn, rule }) {

  const [error, setError] = useState(false);
  const [succsess, setSuccess] = useState(false);
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState(false);
  const test = false;
  const [rules, setRules] = useState(false);
  const [form, setForm] = useState({});
  const regexEmail = /^(?!.*\.{2})([a-zA-Z0-9._-]{1,30})@([a-zA-Z0-9._-]{1,30}\.){1,5}(?!.*\.{2})[a-zA-Z0-9]{1,5}$/;
  const regexTel = /^[+0-9]{1,15}$/;
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  }



  const toggleError = () => {
    setError(!error);
  }
  const toggleSuccess = () => {
    setSuccess(!succsess);
  }
  const toggleCheck = () => {
    setCheck(!check);
  }

  function resetForm(e) {
    document.getElementById("form-reg-reset").reset();
    document.querySelectorAll('.modal-service').forEach(btn =>
      btn.classList.remove('active'))

  }


  async function handleClientReg(e) {
    e.preventDefault();

    if(form.email && form.tel && form.service) {

    const created_timestamp = serverTimestamp();
    const res = await addDoc(collection(db, "ClientRegistration"), {
      email: form.email,
      tel: form.tel,
      service: form.service,
      created_at: created_timestamp
    }).then((res) => {
      setStatus({ type: 'success' });
      toggleSuccess();
      // resetForm();
    })
      .catch((error) => {
        setStatus({ type: 'error', error });
        toggleError();
      });

  } else {
    toggleCheck()
  }
  }



  const handleClick = (e) => {
    e.preventDefault();
    setField('service', e.target.textContent)

    const currTarget = e.currentTarget;
    // disable all 
    document.querySelectorAll('.modal-service').forEach(btn =>
      btn.classList.remove('active'))
    currTarget.classList.add('active');
  }

  return (
    <div className='modal-background'>
      
      <form className="modal-form" onSubmit={handleClientReg} id='form-reg-reset'>
        {btn && <Gricons.GrClose id='closeIcon' className="modal-close-vizitas" onClick={() => closeVizita(false)} />}

        <h1 className="modal-title">Registracija</h1>

        <p>Užsiregistruokite konsultacijai arba veido priežiūros procedūrai.</p>

        <div className="modal-item">
          <label htmlFor="" className="modal-label">El. paštas</label>
          <input type="text" className="modal-input" placeholder=" " onChange={(e) => {
            if (e.target.value.match(regexEmail)) {
              e.target.style.outlineColor = "green";
              // console.log("tinkamas");
              setField('email', e.target.value);
            } else {
              e.target.style.outlineColor = "red";
              setField('email', '');
            }
          }
          }
          />
        </div>
        <div className="modal-item">
          <label htmlFor="" className="modal-label">Tel. numeris</label>
          <input type="number" className="modal-input" placeholder=" " onChange={(e) => {
            if (e.target.value.match(regexTel)) {
              e.target.style.outlineColor = "green";
              setField('tel', e.target.value);
            } else {
              e.target.style.outlineColor = "red";
              setField('tel', '');
            }
          }
          }
          />
        </div>
        <div className="modal-item">
          <label htmlFor="" className="modal-label">Paslauga:</label>
          <div className='modal-dropdown'>
            <span className='modal-service' onClick={handleClick}>Konsultacija (iki 30min.)</span>
            <span className='modal-service' onClick={handleClick} value='resetForm'>Veido procedūra</span>
          </div>

        </div>
        <input type="submit" className="btn-all" value="Siųsti" />
      </form>

      {rule && <div className='modal-form'>
          <h1 className="modal-title" onClick={() => { setRules(!rules) }}>Registracijos taisyklės</h1>
          <p className='modal-paragraph'>
            Registracijos taisyklės yra kurtos tokiu tikslu, jog paslaugai kreiptųsi tik suinteresuoti klientai ir pavyktų išvengti neproduktyvaus laiko, bei išderinto darbo grafiko.
          </p><p className='modal-paragraph'>
            Jei turite abejonių dėl atvykimo, prašome kuo greičiau apie tai informuoti, jog spėtumėme atlikti pakitimus ir galėtumėme priimti kitą laukiantį klientą  į asmeniškai Jums skirtą laiką.
          </p><p className='modal-paragraph'>
            - Atšaukus vizitą po priminimo arba likus mažiau nei 24h iki jo, taikomas 30€ atšaukimo mokestis norint registruotis sekančiam apsilankymui. Pavedimas apmokamas į banko sąskaitą ir po jo paskiriama nauja data.
          </p><p className='modal-paragraph'>
            - Daugiau nei kelis kartus iš eilės keičiant vizito datas, taikomas 40€ depozitinis mokestis, dėl sukeltų abejonių dėl intencijų. Atvykus į vizitą 40€ depozitinė suma išskaičiuojama iš procedūros sumos, neatvykus - negrąžinama.
          </p>
          {/* <div className="modal-line"></div> */}
        </div>
        }
      {error && <PopUp text="Nepavyko išsiūsti, bandykite dar kartą" image="./images/error.svg" close={() => { setError(!error) }} />}
      {check && <PopUp text="Užpildykite visus laukelius" image="./images/error.svg" close={() => { setCheck(!check) }} />}
      {succsess && <PopUp text="Dėkojame, jūsų užklausa gauta" image="./images/check.svg" close={() => {
        setSuccess(!succsess);
        window.location.reload(true);
      }} />}
    </div>
  )
}

export default ModalVizitui