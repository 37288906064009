import React, { useState } from 'react'
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { v4 } from 'uuid';
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';

function Gallery() {

    const [progresR, setProgresR] = useState(0);
	const [photoUploaR, setPhotoUploaR] = useState(null);
    const created_timestampR = serverTimestamp();

    const [progresQ, setProgresQ] = useState(0);
    const [photoUploaQ, setPhotoUploaQ] = useState(null);
    const created_timestampQ = serverTimestamp();
    
    const [progresB, setProgresB] = useState(0);
    const [photoUploaB, setPhotoUploaB] = useState(null);
    const created_timestampB = serverTimestamp();
// ikelti i resultatus
	const handleFormResult = (e) => {
		e.preventDefault();
		// console.log(photoUploaR);
		uploadFilesResult(photoUploaR);
	}

    const uploadFilesResult = (photoFile) => {
		if(!photoFile) return;
		const filesRef = ref(storage, `gallery/result/${v4() + photoFile.name}`);
		const uploadTask = uploadBytesResumable(filesRef, photoFile);

		uploadTask.on("state_changed", (snapshot) => {
			const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
			setProgresR(prog);
		},
		(err) => console.log(err),
		() => {
			getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url)
                addDoc(collection(db, "galleryResults"), {
                    url: url,
                    created_at: created_timestampR
                });
            });
		}
		);
	}

// ikelti i kvalifikacija
    
    const handleFormQualtification = (e) => {
        e.preventDefault();
        // console.log(photoUploaQ);
        uploadFilesQualification(photoUploaQ);
    }
    
    const uploadFilesQualification = (photoFile) => {
        if(!photoFile) return;
        const filesRef = ref(storage, `gallery/qualification/${v4() + photoFile.name}`);
        const uploadTask = uploadBytesResumable(filesRef, photoFile);
    
        uploadTask.on("state_changed", (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgresQ(prog);
        },
        (err) => console.log(err),
        () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url)
                addDoc(collection(db, "galleryQualification"), {
                    url: url,
                    created_at: created_timestampQ
                });
            });
        }
        );
    }



// ikelti brands logo
const handleBrands = (e) => {
    e.preventDefault();
    // console.log(photoUploaR);
    uploadBrands(photoUploaB);
}

const uploadBrands = (photoFile) => {
    if(!photoFile) return;
    const filesRef = ref(storage, `gallery/brands/${v4() + photoFile.name}`);
    const uploadTask = uploadBytesResumable(filesRef, photoFile);

    uploadTask.on("state_changed", (snapshot) => {
        const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresB(prog);
    },
    (err) => console.log(err),
    () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            console.log(url)
            addDoc(collection(db, "BrandsLogo"), {
                url: url,
                created_at: created_timestampB
            });
        });
    }
    );
}

  return (
      <div>
          <form className="mb-3">
              <label className='btn-all' htmlFor='imageUploadR'>Įkelti nuotrauką i Rezultatai</label>
              <input type='file' accept='image/*' style={{ display: 'none' }} id='imageUploadR' className='btn-all' onChange={(e) => { setPhotoUploaR(e.target.files[0]); }} />
              <button onClick={handleFormResult} type='button' className='btn-all'>Įkelti</button>
          </form>
          {(photoUploaR != null) ? <progress style={{ width: '100%' }} max="100" value={progresR}><span>{progresR}</span></progress> : ""}
<br></br>
          <form className="mb-3">
              <label className='btn-all' htmlFor='imageUploadQ'>Įkelti nuotrauką i Kvalifikaciją</label>
              <input type='file' accept='image/*' style={{ display: 'none' }} id='imageUploadQ' className='btn-all' onChange={(e) => { setPhotoUploaQ(e.target.files[0]); }} />
              <button onClick={handleFormQualtification} type='button' className='btn-all'>Įkelti</button>
          </form>

          {(photoUploaQ != null) ? <progress style={{ width: '100%' }} max="100" value={progresQ}><span>{progresQ}</span></progress> : ""}

          <br></br>
          <form className="mb-3">
              <label className='btn-all' htmlFor='imageUploadB'>Įkelti brando logotipą</label>
              <input type='file' accept='image/*' style={{ display: 'none' }} id='imageUploadB' className='btn-all' onChange={(e) => { setPhotoUploaB(e.target.files[0]); }} />
              <button onClick={handleBrands} type='button' className='btn-all'>Įkelti</button>
          </form>

          {(photoUploaB != null) ? <progress style={{ width: '100%' }} max="100" value={progresB}><span>{progresB}</span></progress> : ""}


      </div>
    
  )
}

export default Gallery
