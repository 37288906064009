import React from 'react';
import './GalleryStyle.css';
import { Link } from 'react-router-dom';

function Gallery() {
  return (
    <div className='gallery'>
      <div className="gallery-container">
        <h1>GALERIJA</h1>
        <span className="gallery-line"></span>
        <div className="gallery-choise">
          <Link to='/gallery/galleryqualifications'>
          <div className="gallery-first">
            <img src="./images/gallery1.jpg" alt="" className="gallery-image" />
          </div>
          </Link>
          <Link to='/gallery/galleryresults'>
          <div className="gallery-second">
            <img src="./images/gallery2.jpg" alt="" className="gallery-image" />
          </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Gallery