import React from 'react';
import * as Tiicons from "react-icons/ti";

export const SidebarData = [
    {
        title: 'PASLAUGOS',
        path: '/services',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'REGISTRACIJA',
        path: '/registration',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    // {
    //     title: 'APIE MANE',
    //     path: '/about',
    //     icon: <Tiicons.TiHomeOutline />,
    //     cName: 'nav-text'
    // },
    {
        title: 'DOVANŲ KUPONAS',
        path: '/giftcard',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'GALERIJA',
        path: '/gallery',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    },
    {
        title: 'KONTAKTAI',
        path: '/contacts',
        email: 'emailas',
        tel: '+37000000',
        icon: <Tiicons.TiHomeOutline />,
        cName: 'nav-text'
    }
]