import React, { useState } from 'react';
import './giftcardStyle.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FormInput } from '../../Modal/ReusableForm';
import PopUp from '../../Modal/PopUp';

function GiftCard() {

  const [error, setError] = useState(false);
  const [succsess, setSuccess] = useState(false);
  const [check, setCheck] = useState(false);
  const [status, setStatus] = useState(false);
  const regexEmail = /^(?!.*\.{2})([a-zA-Z0-9._-]{1,30})@([a-zA-Z0-9._-]{1,30}\.){1,5}(?!.*\.{2})[a-zA-Z0-9]{1,5}$/;
  const regexTel = /^[+0-9]{1,15}$/;
  
  const [form, setForm] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  }


  const toggleError = () => {
    setError(!error);
  }
  const toggleSuccess = () => {
    setSuccess(!succsess);
  }
  const toggleCheck = () => {
    setCheck(!check);
  }

  function regSuccess() {
    toast.success('Registracija atlikta sėkmingai, susisieksiu telefonu arba El. paštu', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  };
  function regError() {
    toast.error('Registracija nepavyko, bandykite dar kartą', {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  function resetForm(e) {
    // document.getElementById("form-coupon-reset").reset();
    document.querySelectorAll('.coupon .value').forEach(btn =>
      btn.classList.remove('active'))

  }


  // console.log(form);
  async function handleNewCoupon(e) {
    e.preventDefault();
    
    if(form.email && form.tel && form.couponType && form.couponValue) {
      // console.log("viskas atitiko");
      // regSuccess();
      const created_timestamp = serverTimestamp();
      
      const res = await addDoc(collection(db, "GiftCard"), {
          email: form.email,
          tel: form.tel,
          couponType: form.couponType,
          couponValue: form.couponValue,
          created_at: created_timestamp
        }).then((res) => {
            setStatus({ type: 'success' });
          toggleSuccess();
        // regSuccess();
        // resetForm();
      })
        .catch((error) => {
          setStatus({ type: 'error', error });
          // regError();
          toggleError();
        });
        
      } else {
        // console.log("kazkas netiko");
        toggleCheck();
        // regError();
      
    }
  }



  const handleCoupon = (e) => {
    e.preventDefault();
    setField('couponType', e.target.textContent)

    const currTarget = e.currentTarget;
    // disable all 
    document.querySelectorAll('.coupon').forEach(btn =>
      btn.classList.remove('active'))
    currTarget.classList.add('active');
  }

  const handleValue = (e) => {
    e.preventDefault();
    setField('couponValue', e.target.textContent)

    const currTarget = e.currentTarget;
    // disable all 
    document.querySelectorAll('.value').forEach(btn =>
      btn.classList.remove('active'))
    currTarget.classList.add('active');
  }


  return (
    <div className='modal-background'>
      <form className="modal-form" onSubmit={handleNewCoupon} id='form-reg-reset'>
        <h1 className="modal-title">Dovanų kuponas</h1>
        <div className="modal-item">
          <label htmlFor="" className="modal-label">El. paštas</label>
          <input type="text" className="modal-input" placeholder=" " onChange={(e) => {
            if (e.target.value.match(regexEmail)) {
              e.target.style.outlineColor = "green";
              // console.log("tinkamas");
              setField('email', e.target.value);
            } else {
              e.target.style.outlineColor = "red";
              setField('email', '');
            }
          }
          }
          />
        </div>
        <div className="modal-item">
          <label htmlFor="" className="modal-label">Tel. numeris</label>
          <input type="number" className="modal-input" placeholder=" " onChange={(e) => {
            if (e.target.value.match(regexTel)) {
              e.target.style.outlineColor = "green";
              setField('tel', e.target.value);
            } else {
              e.target.style.outlineColor = "red";
              setField('tel', '');
            }
          }
          }
          />
        </div>
        <div className='modal-dropdown'>
          <span className='coupon' onClick={handleCoupon}>Elektroninis</span>
          <span className='coupon' onClick={handleCoupon}>Klasikinis</span>
        </div>
        <div className="price-container">
          <span className='btn-all value' onClick={handleValue}>50€</span>
          <span className='btn-all value' onClick={handleValue}>70€</span>
          <span className='btn-all value' onClick={handleValue}>100€</span>
          <span className='btn-all value' onClick={handleValue}>200€</span>
        </div>
        <span className='modal-desc'>Rekomenduojame įsigyti fiksuotos kainos dovanų kuponą, tokiu būdu svečio oda bus individualiai įvertinta ir pritaikytas naudingiausias sprendimas ar namų priežiūra.</span>


        <input type="submit" className="btn-all" value="Siųsti" />
      </form>
      {error && <PopUp text="Nepavyko išsiūsti, bandykite dar kartą" image="./images/error.svg" close={() => {setError(!error)}} />}
      {check && <PopUp text="Užpildykite visus laukelius" image="./images/error.svg" close={() => {setCheck(!check)}} />}
      {succsess && <PopUp text="Dėkojame, jūsų užklausa gauta" image="./images/check.svg" close={() => {
        setSuccess(!succsess);
        window.location.reload(true);
      }} />}
      {/* <ToastContainer /> */}
    </div>
  )
}

export default GiftCard