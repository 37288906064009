import React from 'react';
import './contactsStyle.css';

function Contacts() {

  function call() {
    window.open('tel:+37069058465', '_self');
  }
function email() {
    window.location.href ='mailto:evdderma@gmail.com';
  }
function navig() {
    window.open('https://www.google.com/maps/dir/?api=1&destination=Klaipėdos+gatvė+17a,+Gargždai&dir_action=navigate');
  }

  return (
    <div className="contacts">
      <div className="contact-container">
        
        <div className="contact-form">
          <h1>Susisiekite</h1>
          {/* <div className="btn-default" onClick={navig}>Klaipėdos g. 17a</div> */}
          {/* <div className="btn-default" onClick={call}>+37069058465</div> */}
          {/* <div className="btn-default" onClick={email}>e.s.kosmetologija@gmail.com</div> */}
          {/* <div className="btn-default btn-value">I-V 10.00 - 18.00 val.</div> */}
          
          <div className="contact-label">
            <label className="btn-default btn-label">Įmonė</label>
            <label className="btn-default btn-value">MB Ev Derma</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">Įmonės kodas</label>
            <label className="btn-default btn-value">306370319</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">Vadovė</label>
            <label className="btn-default btn-value">Eva Šarlota Venckutė, direktorė</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">Adresas</label>
            <label className="btn-default btn-value" onClick={navig}>Klaipėdos g. 17a, Gargždai</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">Tel. nr</label>
            <label className="btn-default btn-value" onClick={call}>+37069058465</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">El. paštas</label>
            <label className="btn-default btn-value" onClick={email}>evdderma@gmail.com</label>
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label">Darbo laikas</label>
            <label className="btn-default btn-value">I-V 10.00 - 18.00</label>
            {/* <label className="btn-default btn-value">VI 11.00 - 14.00</label> */}
          </div>
          <div className="contact-label">
            <label className="btn-default btn-label hide">Darbo laikas</label>
            <label className="btn-default btn-value">VI 11.00 - 14.00</label>
          </div>
        </div>
        <div className="contact-map">
          <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d694.3701192845581!2d21.39378998816945!3d55.71089565431465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e4e104e8e3a2df%3A0x53b1287ddc126c33!2sKlaip%C4%97dos%20g.%2017a%2C%20Garg%C5%BEdai%2096131!5e1!3m2!1slt!2slt!4v1665234084952!5m2!1slt!2slt" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  )
}

export default Contacts

