import React, { useEffect, useState } from 'react';
import data from './fdata.json';
import './feedbackStyle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
// import "swiper/css/navigation";

function Feedback() {
    const [image, setImage] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if (image === feedback.length - 1) {
    //       setImage(0);
    //     } else {
    //       setImage(image + 1);
    //     }
    //   }, 3000);

    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, [image]);

    const feedback = data.map((item, index) => {
        return (
            <SwiperSlide key={index}>
                <div className="feedback-item">
                    <div className="feedback-top-container">
                        {/* <img src={item.photo} alt="" className="feedback-image" /> */}
                        <div className='feedback-title-container'>
                            <p className="feedback-title">{item.name}</p>
                            <p className="feedback-type">{item.type}</p>
                            <p className="feedback-date">{item.date}
                                {/* {item.place} */}
                            </p>
                        </div>
                        {/* <div className="feedback-placeLogo">{item.placeLogo}</div> */}
                    </div>
                    <div className="feedback-desc-container">
                        <p className="feedback-desc">{item.feedback}</p>
                    </div>
                </div>
            </SwiperSlide>
        )
    })

    return (
        <div className='feedback'>

            <div className="swiper-container">
                <div className="feedback-image">

                    <img src='images/home.jpg' />
                </div>
                <div className="swiper-background">
                    <div className="feedback-me">
                        <h1>Atsiliepimai</h1>
                    </div>
                    <Swiper
                        // spaceBetween={0}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                            type: 'bullets',
                        }}
                        // navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="mySwiper"
                    >
                        {feedback}

                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Feedback
