import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from "./components/navbar/Navbar";
import Pages from "./components/pages/Pages";
import "./style.css";

function App() {

  return (
    <div className="App">
      <Router>
          <Navbar />
          <Pages />
      </Router>
      {/* <Home /> */}
    </div>
  );
}

export default App;
