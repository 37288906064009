import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import "./Navbar.css";
import * as Gricons from "react-icons/gr";
import * as Icons from "react-icons/fi";
import SearchBar from './SearchBar';

function Navbar() {

  const [dropdown, setDropdown] = useState(false);
  const showDropdown = () => setDropdown(!dropdown);

  function call() {
    window.open('tel:+37069058465', '_self');
  }
  function email() {
    window.location.href = 'mailto:e.s.kosmetologija@gmail.com';
  }
  // function navig() {
  //     window.open('https://www.google.com/maps/dir/?api=1&destination=adressas&dir_action=navigate');
  //   }
  return (

    <div className='navbar-container'>
      {/* logo with home page link*/}
      <div className="navbar-logo">
        <Link to="/" >
          <img src="./images/logoGrey.svg" alt="" className="navbar-logo" />
        </Link>

      </div>

      {/* nav meniu */}
      <div className="navbar-menu">
        {SidebarData.map((item, index) => {
          return (
            <li key={index} className={item.cName}>
              {/* <a href={item.path}>{item.icon}{item.title}</a> */}
              <Link to={item.path} className='nav-title' onClick={() => setDropdown(false)}>
                {/* {item.icon} */}
                <span>{item.title}</span>
              </Link>
            </li>
          )
        })}
      </div>
      {/* search */}
      {/* <SearchBar /> */}
      {/* dropdown meniu */}
      <div className="navbar-dropdown">
        {/* hamburger menu icon with open function*/}
        <div className="navbar-hamburger" onClick={showDropdown}><Gricons.GrMenu className="navbar-icons" /></div>
        {/* dropdown menu */}
        <div className={dropdown ? 'dropdown open' : 'dropdown'}>
          <div className="dropdown-container">
            <div className="close" onClick={showDropdown}><Gricons.GrClose className="navbar-icons" /></div>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  {/* <a href={item.path}>{item.icon}{item.title}</a> */}
                  <Link to={item.path} className='nav-title' onClick={() => setDropdown(false)}>
                    {/* {item.icon} */}
                    <span>{item.title}</span>
                  </Link>
                </li>
              )
            })}
            <span className="nav-menu-line"></span>
            <div className="languages">
              <div className="language">EN</div>
              <div className="language">RU</div>
              <div className="language">LT</div>
            </div>
            <span className="nav-menu-line"></span>
            <div className="menu-contacts">
              <div className="menu-contact" onClick={email}>
                <Icons.FiMail className='menu-contact-icons' />
                <span className="menu-contact-text">evdderma@gmail.com</span>
              </div>
              <div className="menu-contact" onClick={call}>
                <Icons.FiPhone className='menu-contact-icons' />
                <span className="menu-contact-text">+37069058465</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Navbar