import React, { useState } from 'react'
import { collection, addDoc } from "firebase/firestore";
import { db } from '../../firebase';
import { doc, setDoc, updateDoc } from "firebase/firestore";

function AddText() {

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [signature, setSignature] = useState('');

    const updateDesc = async () => {

        // Create an initial document to update.
        const updateTitle = doc(db, "AllTexts", "aboutme");
        // await setDoc(updateTitle, {
        //     title: "",
        //     desc: "",
        //     // desc: { food: "Pizza", color: "Blue", subject: "recess" },
        //     signature: ""
        // });

        // To update age and favorite color:
        await updateDoc(updateTitle, {
            "desc": desc,
            // "favorites.color": "Red"
        });
    }

    const updateTitle = async () => {
        const updateTitle = doc(db, "AllTexts", "aboutme");
        await updateDoc(updateTitle, {
            "title": title,
        });
    }

    const updateSignature = async () => {
        const updateTitle = doc(db, "AllTexts", "aboutme");
        await updateDoc(updateTitle, {
            "signature": signature,
        });
    }

    return (
        <div className='add-service'>
            <label htmlFor="" className="label-title">Apie mane skambi frazė</label>
            <textarea type="text" className="add-desc" onChange={(e) => setTitle(e.target.value)} rows="8"></textarea>
            <button className="btn-all" onClick={updateTitle}>Pakeisti</button>
            <span className="break-line"></span>
            <label htmlFor="" className="label-title">Apie mane aprašymas</label>
            <textarea type="text" className="add-desc" onChange={(e) => setDesc(e.target.value)} rows="8"></textarea>
            <button className="btn-all" onClick={updateDesc}>Pakeisti</button>
            <span className="break-line"></span>
            <label htmlFor="" className="label-title">Apie mane parašas</label>
            <input type="text" className="add-title" onChange={(e) => setSignature(e.target.value)} />
            <button className="btn-all" onClick={updateSignature}>Pakeisti</button>
        </div>
    )
}

export default AddText


// Add a new document with a generated id.
