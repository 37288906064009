import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from '../../../firebase';
import React, { useEffect, useState } from 'react';
import './servicesStyle.css';
import ModalRules from "../../Modal/ModalRules";




function Services() {

  const [todos, setTodos] = useState([]);
  const [openRules, setOpenRules] = useState(false);

  const fetchPost = async () => {

    await getDocs(query(collection(db, "priceList"), orderBy('created_at')))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        setTodos(newData);
        // console.log(todos, newData);
      })

  }

  useEffect(() => {
    fetchPost();
  }, [])
  return (
    <div className='services' id='services'>
      <div className="service-rules-container">

        <div className="service-rules btn-rules" onClick={() => setOpenRules(true)}>Registracijos taisyklės</div>
      </div>
      <div className="nav-menu-lines"></div>
      {
        todos?.map((todo, i) => (
          <div key={i}>
            {i !== 0 && <div className="nav-menu-lines"></div>}
            <div className="service-title-container">
              <span className="service-title">{todo.title}</span>
              <span className="service-price">{todo.nuo ? "" : "nuo"} {todo.price}€</span>
            </div>
            <span className="service-duration">iki {todo.duration}</span>
            <div className="row">
              <span className="service-desc">{todo.desc}</span>
            </div>
          </div>
        ))
      }
      {openRules && <div className="modal"><ModalRules btn={true} closeVizita={setOpenRules} /></div>}
    </div>
  )
}

export default Services