import React from 'react';
import './pagesStyle.css';


function About() {
  return (

    <div className="about">
    <div className="about-background">
    <div className='about-container'>
      <img src="./images/about.jpg" alt="" className="about-image" />

      <h3 className='about-title'>Apie mane</h3>
      <p className="about-desc">
        Aš Eva Šarlota kartu su pHformula kosmetika darau stebuklus. 
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate dicta sint adipisci non eveniet delectus ipsa dolorum odit illum facere!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi porro recusandae veritatis provident omnis, distinctio cupiditate incidunt quidem odio quaerat?
        </p>
      {/* <div className="btn-all">Apie mane</div> */}
    </div>
    </div>
    </div>
  )
}

export default About