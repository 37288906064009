import React, { useState } from 'react'
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from '../../firebase';
import './AdminStyle.css';
import Gallery from './Gallery';
import AddText from './AddText';
import ServicesUpdate from './ServicesUpdate';

function Admin() {

    const duration = [
        "15 min",
        "30 min",
        "45 min",
        "1 val",
        "1 val 15 min",
        "1 val 30 min",
        "1 val 45 min",
        "2 val",
        "2 val 15 min",
        "2 val 30 min",
        "2 val 45 min",
        "3 val"
    ]


  const [form, setForm] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
  }

  // Add a new document in collection "priceList"
  async function handleAdd(e) {
    e.preventDefault();
    const created_timestamp = serverTimestamp();

    const res = await addDoc(collection(db, "priceList"), {
      title: form.title,
      price: form.price,
      desc: form.desc,
      duration: form.duration,
      created_at: created_timestamp
    });
    document.getElementById("form-reset").reset();
  }


    const items = duration.map((item, index) => {

        return (
            <option key={index} value={item}>{item}</option>

        )
    })

  return (
    <div className='Admin-container'>
          <form onSubmit={handleAdd} className="add-service" id='form-reset'>
              <label htmlFor="" className="label-title">Procedūros/paslaugos pavadinimas</label>
              <input type="text" className="add-title" onChange={(e) => setField('title', e.target.value)} />
              <label htmlFor="" className="label-price">Kaina eurais</label>
              <input type="number" className="add-price" onChange={(e) => setField('price', e.target.value)} />
              <label htmlFor="" className="label-desc">Aprašymas</label>
              <textarea type="text" className="add-desc" onChange={(e) => {(e.target.value.trim().length !== 0) ? setField('desc', e.target.value) : setField('desc', "")}} rows="8"></textarea>
              <label htmlFor="" className="label-duration">Procedūros/paslaugos trukmė</label>
                <select value={form.duration} onChange={(e) => setField('duration', e.target.value)}>
                    {items} 
                </select>
              <input type="submit" value="Pridėti" />
          </form>

          <span className="break-line"></span>
          <AddText />
          <span className="break-line"></span>
          <Gallery />
          <span className="break-line"></span>
          {/* <GetServices /> */}
          <ServicesUpdate />
    </div>
  )
}

export default Admin
