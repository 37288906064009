import React, { useEffect, useState } from "react";
import ServicesItem from "./ServicesItem";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../firebase";
// import { fetchPost } from "../pages/Services"

const ServicesUpdate = () => {
  const [response, setResponse] = useState([]);
  const [todos, setTodos] = useState([]);

  const fetchPost = async () => {
       
    await getDocs(query(collection(db, "priceList"), orderBy('created_at')))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }));
            setTodos(newData);                
            setResponse(newData);                
            // console.log(newData);
        })
   
}
  //call data on page load
  useEffect(() => {
    fetchPost(setResponse);
  }, []);

//   console.log(response);
  return (
    <div className='services'>

      {response.map((items, index) => (
        <ServicesItem key={index}
        //   prodId={items.productId}
        //   key={items.productId}
        itemID={items.id}
          itemTitle={items.title}
          itemPrice={items.price}
          itemDuration={items.duration}
          itemDesc={items.desc}
        />
      ))}
    </div>
  );
};

export default ServicesUpdate;
