import React, { useEffect, useState } from 'react';
// import './feedbackStyle.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../../firebase';
// import "swiper/css/navigation";

function Brands() {
    const [image, setImage] = useState(0);
    const [isOpen, setIsOpen] = useState(true);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if (image === feedback.length - 1) {
    //       setImage(0);
    //     } else {
    //       setImage(image + 1);
    //     }
    //   }, 3000);

    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, [image]);

    const [logos, setLogos] = useState([]);

    const fetchBrands = async () => {

        await getDocs(query(collection(db, "BrandsLogo"), orderBy('created_at', 'asc')))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ ...doc.data(), id: doc.id }));
                setLogos(newData);
                // console.log(todos, newData);
            })

    }

    useEffect(() => {
        fetchBrands();
    }, [])

    const feedback = logos.map((item, index) => {
        return (
            <SwiperSlide key={index}>
                <img
                    src={item.url}
                    style={{ width: "85%", display: "block" }}
                />
            </SwiperSlide>
        )
    })

    return (
        // <div className='brands-container'>
        //     <Swiper
        //         // spaceBetween={0}
        //         centeredSlides={true}
        //         autoplay={{
        //             delay: 3000,
        //             disableOnInteraction: false,
        //         }}
        //         pagination={{
        //             clickable: true,
        //             // type: 'bullets',
        //         }}
        //         // navigation={true}
        //         modules={[Autoplay, Pagination, Navigation]}
        //         className="mySwiper"
        //     >
        //         {feedback}
        //     </Swiper>
        // </div>
        <div className='brands-container'>
            {/* <div className="swiper-background"> */}
                <div className="brands-title">
                    <p className='brands-text'>Partneriai</p>
                </div>
                <Swiper
                    spaceBetween={10}
                    slidesPerView={3}
                    // slidesPerGroupSkip={3}
                    slidesPerGroup={3}
                    centeredSlides={false}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    // pagination={{
                    //     // clickable: true,
                    //     // type: 'bullets',
                    // }}
                    // navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {feedback}

                </Swiper>
        {/* </div> */}
    </div>
    )
}

export default Brands
