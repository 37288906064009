import React, { useState } from 'react'
import './modalStyle.css'

export default function PopUp({ close, text, image }) {

    const [popup, setPopup] = useState(false);

    const togglePopUp = () => {
        setPopup(!popup);
    }

    return (
        <div>
            <div className="popup-modal">
                <div className="popup-overlay"></div>
                <div className="popup-content">
                    <img src={image} alt="" className="popup-img" />
                    <p className="popup-msg">
                        {text}
                    </p>
                    <button onClick={close} className='popup-close'>X</button>
                </div>
            </div>
        </div>
    )
}
