import React, { useState } from 'react';
import './modalStyle.css';
import * as Gricons from "react-icons/gr";
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from '../../firebase';
import PopUp from './PopUp';

function ModalRules({ closeVizita, btn }) {

    const [error, setError] = useState(false);
    const [succsess, setSuccess] = useState(false);
    const [check, setCheck] = useState(false);
    const [status, setStatus] = useState(false);
    const [form, setForm] = useState({});
    const regexEmail = /^(?!.*\.{2})([a-zA-Z0-9._-]{1,30})@([a-zA-Z0-9._-]{1,30}\.){1,5}(?!.*\.{2})[a-zA-Z0-9]{1,5}$/;
    const regexTel = /^[+0-9]{1,15}$/;
    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
    }



    const toggleError = () => {
        setError(!error);
    }
    const toggleSuccess = () => {
        setSuccess(!succsess);
    }
    const toggleCheck = () => {
        setCheck(!check);
    }

    function resetForm(e) {
        document.getElementById("form-reg-reset").reset();
        document.querySelectorAll('.modal-service').forEach(btn =>
            btn.classList.remove('active'))

    }


    async function handleClientReg(e) {
        e.preventDefault();



        toggleCheck()

    }


    const handleClick = (e) => {
        e.preventDefault();
        setField('service', e.target.textContent)

        const currTarget = e.currentTarget;
        // disable all 
        document.querySelectorAll('.modal-service').forEach(btn =>
            btn.classList.remove('active'))
        currTarget.classList.add('active');
    }

    return (
        <div className='modal-background'>
            <form className="modal-form" onSubmit={handleClientReg} id='form-reg-reset'>
                {btn && <Gricons.GrClose id='closeIcon' className="modal-close-vizitas" onClick={() => closeVizita(false)} />}
                <h1 className="modal-title">Registracijos taisyklės</h1>

                <p className='modal-paragraph'>
                    Registracijos taisyklės yra kurtos tokiu tikslu, jog paslaugai kreiptųsi tik suinteresuoti klientai ir pavyktų išvengti neproduktyvaus laiko, bei išderinto darbo grafiko.
                </p>
                <p className='modal-paragraph'>
                    Jei turite abejonių dėl atvykimo, prašome kuo greičiau apie tai informuoti, jog spėtumėme atlikti pakitimus ir galėtumėme priimti kitą laukiantį klientą  į asmeniškai Jums skirtą laiką.
                </p>
                <p className='modal-paragraph'>
                    - Atšaukus vizitą po priminimo arba likus mažiau nei 24h iki jo, taikomas 30€ atšaukimo mokestis norint registruotis sekančiam apsilankymui. Pavedimas apmokamas į banko sąskaitą ir po jo paskiriama nauja data.
                </p>
                <p className='modal-paragraph'>
                    - Daugiau nei kelis kartus iš eilės keičiant vizito datas, taikomas 40€ depozitinis mokestis, dėl sukeltų abejonių dėl intencijų. Atvykus į vizitą 40€ depozitinė suma išskaičiuojama iš procedūros sumos, neatvykus - negrąžinama.
                </p>

                <input type="submit" className="btn-all" value="Supratau" onClick={() => closeVizita(false)} />
            </form>
        </div>
    )
}

export default ModalRules